<template>
  <FormWrapper
    editableName
    :form="$store.state.attendance.form[formName]"
    :hideForm="hideForm"
    :handleOpened="() => handleProps('opened', !$store.state.attendance.form[formName].opened)"
    @onEditName="onEditName"
    :attachModule="formName"
    :hasExam="hasExamValues('exam')"
    >
    <!-- @getData="importAttendanceData(null, $event, true)" -->
    <b-row>
      <b-col>
        <b-row>
          <b-col cols="6">
            <div class="form-group mb-0">
              <div class="input-eye">
                <p class="tag-eye"><EyeFill /> D</p>
                <input
                  type="text"
                  class="form-control"
                  autocomplete="off"
                  :value="$store.state.attendance.form[formName].fields.olhoDireito"
                  :readonly="!canEdit"
                  @input="el => updateForm('olhoDireito', el.target.value)"
                  @blur="updateMedicalRecord($store.state.attendance.form[formName])"
                  :id="`${formName}-direito`"
                  placeholder="Resultado"
                />
              </div>
            </div>
          </b-col>
          <b-col cols="6">
            <div class="form-group mb-0">
              <div class="input-eye">
                <p class="tag-eye"><EyeFill /> E</p>
                <input
                  type="text"
                  class="form-control"
                  autocomplete="off"
                  :value="$store.state.attendance.form[formName].fields.olhoEsquerdo"
                  :readonly="!canEdit"
                  @input="el => updateForm('olhoEsquerdo', el.target.value)"
                  @blur="updateMedicalRecord($store.state.attendance.form[formName])"
                  :id="`${formName}-esquerdo`"
                  placeholder="Resultado"
                />
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <button
        class="btn blue-underline button-copy center"
        @click="copyEyeValues"
        v-if="canEdit"
        v-b-tooltip.hover title="Copiar valores O.D para O.E"
        >
        <Copy />
      </button>
    </b-row>
    <b-row class="mt-2" v-if="getExtraTableKeys().length">
      <b-col>
        <table class="additional-exam-table" :class="`${!canEdit ? 'disabled': ''}`">
          <thead>
            <tr>
              <th></th>
              <th 
                v-for="(key, index) in getExtraTableKeys()"
                :key="index"
              >
                {{ key }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><EyeFill />D</td>
              <td 
                v-for="(key, index) in getExtraTableKeys()"
                :key="index"
              >
                {{ $store.state.attendance.form[formName].fields[`olhoDireito${key}`] }}
              </td>
            </tr>
            <tr>
              <td><EyeFill />E</td>
              <td 
                v-for="(key, index) in getExtraTableKeys()"
                :key="index"
              >
                {{ $store.state.attendance.form[formName].fields[`olhoEsquerdo${key}`] }}
              </td>
            </tr>
          </tbody>
        </table>
      </b-col>
    </b-row>
    <b-row class="mt-2" v-if="getExtraDescriptiveKeys().length">
      <b-col>
        <div class="form-group" v-for="(key, index) in getExtraDescriptiveKeys()" :key="index">
          <label>
            {{ key.charAt(0).toUpperCase() + key.slice(1) }}</label>
            <TextArea
              :id="`extra-${key}`"
              :value="$store.state.attendance.form[formName].fields[key]"
              :readonly="!canEdit"
              rows="1"
              placeholder="Descrever"
            />
        </div>
      </b-col>
    </b-row>
  </FormWrapper>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
// import attendanceDataMixin from '@/mixins/Attendance/attendanceDataMixin'
// import { registerAdditionalExamModule } from '@/store/registerModule'

export default {
  props: {
    formName: String,
  },
  components: {
    FormWrapper: () => import('@/components/Attendance/Forms/FormWrapper'),
    EyeFill: () => import('@/assets/icons/eye-fill.svg'),
    Copy: () => import('@/assets/icons/copy.svg'),
    TextArea: () => import('@/components/General/TextArea'),
  },
   data () {
    return {
      destroying: false
    }
  },
  // mixins: [attendanceDataMixin('medicalRecordsStore', 'customFormName')],
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      customFormEdit: state => state.attendance.customFormEdit,
      medicalRecordsStore: state => state.attendance.medicalRecordsStore,
      attendance: state => state.attendance,
    }),
    ...mapGetters('attendance', ['exam']),
    customFormName() {
      return this.$store?.state?.attendance?.form[this.formName]?.customName
    }
  },
  methods: {
    ...mapActions('attendance/form', ['updateMedicalRecord', 'updateForms', 'destroyMedicalRecord', 'handleActiveModule', 'addAdditionalExamForm']),
    handleProps(key, value) {
      this.$store.dispatch(`attendance/form/${this.formName}/handleProps`, ({ key, value }), { root: true })
    },
    updateForm(key, value) {
      this.$store.dispatch(`attendance/form/${this.formName}/handleFields`, ({ key, value }), { root: true })
    },
    copyEyeValues() {
      this.updateForm('olhoEsquerdo', this.$store.state.attendance.form[this.formName].fields.olhoDireito)
      setTimeout(() => { this.updateMedicalRecord(this.$store.state.attendance.form[this.formName]) }, 500)
    },
    onEditName() {
      if(!this.destroying){        
        const name = this.$store.state.attendance.form[this.formName].name
        if(!name?.length) return;

        this.updateForm('customName', name)
        if (this.customFormEdit) {
          this.updateForms([{
              id: this.$store.state.attendance.form[this.formName]?.id,
              name: this.$store.state.attendance.form[this.formName]?.name,
              customName: this.$store.state.attendance.form[this.formName]?.name,
              opened: this.$store.state.attendance.form[this.formName]?.opened,
              showing: this.$store.state.attendance.form[this.formName]?.showing,
            }]
          )
        } else {
          this.updateMedicalRecord(this.$store.state.attendance.form[this.formName])
        }
      }
    },
    getExtraTableKeys() {
      return [...new Set(
        Object.keys(this.$store.state.attendance.form[this.formName].fields)
        .filter(k => !['olhoDireito', 'olhoEsquerdo', 'customName'].includes(k) && k.includes('olho'))
        .map(field => field.replace(/olho(Direito|Esquerdo)/, '')))];
    },
    getExtraDescriptiveKeys() {
      return [...new Set(
        Object.keys(this.$store.state.attendance.form[this.formName].fields)
        .filter(k => !['olhoDireito', 'olhoEsquerdo', 'customName'].includes(k) && !k.includes('olho')))];
    },
    hideForm() {
      this.destroying = true
      this.handleProps('showing', false)
      this.$store.state.attendance.form[this.formName].fields.olhoDireito = null
      this.$store.state.attendance.form[this.formName].fields.olhoEsquerdo = null
      this.destroyMedicalRecord(this.$store.state.attendance.form[this.formName])
      setTimeout(() => {
        this.destroying = false
      }, 500)
    },
    // async importAttendanceData(medicalRecords = null, type, unit = null) {
    //   const data = medicalRecords || this[type].medical_records

    //   const formNames = Object.keys(this.attendance.form).map(key => this.attendance.form[key].name).filter(name => name)
    //   const medicalRecordsFormName = data.map(el => el?.value?.customName).filter(name => name)
    //   const formNamesToCreate = medicalRecordsFormName.filter(name => !formNames.includes(name))

    //   if (formNamesToCreate.length && !unit) {
    //    formNamesToCreate.map(name => {
    //     const time = new Date().getTime()
    //     const formId = `exame-personalizado-${time}`
    //     registerAdditionalExamModule(formId, name)
    //     this.handleActiveModule(formId)
        
    //       this.updateForms([{
    //         id: this.$store.state.attendance.form[formId].id,
    //         name: this.$store.state.attendance.form[formId].name,
    //         opened: this.$store.state.attendance.form[formId].opened,
    //         showing: this.$store.state.attendance.form[formId].showing,
    //       }])
    //     })
    //   }

    //   const formData = data.find(el => { return el?.value?.customName === this.customFormName})

    //   if (!formData?.id) return
    //   Object.keys(formData.value).map(key => {
    //     if (formData.value[key]) {
    //       this.updateForm(`${key}`, formData.value[key])
    //     }
    //   })
    //   await this.updateMedicalRecord(this.$store.state.attendance.form[this.formName])
    // },
    hasExamValues(attendanceType) {
      if (!this[attendanceType]) return false
      const record = this[attendanceType].medical_records?.find(el => {  return el?.value?.customName === this.customFormName})

      if (!record) return false
      return Object.keys(record.value).some(key => {
        return !!record.value[key]
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.additional-exam-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
  text-align: center;

  th {
    font-size: 12px;
    line-height: 16px;
    color: var(--type-active);
  }

  td {
    border: 1px solid var(--neutral-300);
    padding: 6px 12px;
  }

  th:first-child {
    width: 60px;
  }

  td:first-child svg {
    margin-bottom: 2px;
  }

  tr:first-child td {
    border-bottom: none;
  }

  tr :not(td:last-child) {
    border-right: none;
  }

  tr:first-child td:first-child {
    border-top-left-radius: 8px;
  }
  tr:first-child td:last-child {
    border-top-right-radius: 8px;
  }
  tr:last-child td:first-child {
    border-bottom-left-radius: 8px;
  }
  tr:last-child td:last-child {
    border-bottom-right-radius: 8px;
  }

  td:not(:first-child) {
    font-size: 14px;
  }

  td:first-child {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    svg {
      width: 16px;
      height: 16px;
      fill: var(--type-active);
      margin-right: 4px;
    }
  }

  &.disabled {
    td:not(:first-child) {
      background-color: #E9ECEF;
      color: var(--type-placeholder);
    }
  }
}

.form-control {
  font-size:14px;
}
</style>
